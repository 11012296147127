body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.a-card {
  margin: 40px;
}

.a-card-image{
  border-radius: 4px;
  width: 350px;
  height: 350px;
  box-shadow: 0px 0px 7px 1px #ccc;

}

.a-card-info{
  margin-top: 10px;
  min-height: 100px;
}

.a-card-title{
  font-size: 24px;
  margin: 0px;
}

.a-card-sub-title{
  font-size: 16px;
  margin: 0px;
}

.a-about-text{
  font-size: 22px;

}
.h-description-text{
  font-size: 22px;
}
.h-subdescription-text{
  font-size: 28px;
}

.success-msg{
  color: #57A773;
  padding: 10px 15px;
}

.err-msg{
  color: #EE6352;
  padding: 10px 15px;
}